.user_mode .sponsorScroller .column-1{
    width: 100%;
    height: 135px;
    padding: 0px;
    overflow: hidden;
    white-space: nowrap;
    box-sizing: border-box;
    font-size: 0;
}

#sponsorScrollerGroup {
    position: relative;
    left: 0;
    padding: 0;
    margin: 0 35px;
}

.user_mode .layoutContainer.layout-100.sponsorScroller {
    background: #fff;
    margin: 0;
    position: relative;
    padding: 0;
}

.sponsorScroller .column-1 {
    text-align: center;
}

.user_mode .layoutContainer.sponsorScroller .pageEl {
    display: inline-block;
    width: 185px;
    text-align: center;
    //padding: 0 10px;
    vertical-align: middle;
    box-sizing: border-box;
}

.user_mode .layoutContainer.sponsorScroller .pageEl .heroPhotoElement {
    overflow: hidden;
    margin: 0px;
}

.user_mode .layoutContainer.sponsorScroller .pageEl .heroPhotoElement img {
    height: auto;
    max-height: 135px;
    max-width: 135px;
    width: auto;
    border: 0px;
    padding: 20px 0px;
}
/*
.user_mode .layoutContainer.sponsorScroller .pageEl .heroPhotoElement a {
  opacity: 0.90;
  &:hover{
    opacity: 1;
  }
}
*/

#sponsorScrollerPrev,
#sponsorScrollerNext {
    color: rgba(255, 255, 255, 0);
    width: 35px;
    line-height: 135px;
    height: 135px;
    background: #fff;
    position: absolute;
    top: 0;
    left: 0;
    cursor: pointer;
    transition: background-color 0.5s ease;
}

#sponsorScrollerNext {
    left: auto;
    right: 0;
    background: #fff url('../images/theme/arrow-right.svg') center center no-repeat;
}
#sponsorScrollerPrev {
    background: #fff url('../images/theme/arrow-left.svg') center center no-repeat;
}

#sponsorScrollerPrev:hover,
#sponsorScrollerNext:hover {
  //
}
/* Scales down logos in edit mode so they're easier to manage */

.edit_mode .layoutContainer.sponsorScroller div.drag {
    display: inline-block;
    width: 33%;
    float: left;
    .heroPhotoElement img{
      max-height: 100px;
      width: auto;
      border: 0px;
      max-width: 100px;
    }
}
//

.sponsorBar {
  display: block;
  margin: 0 auto;
  max-width: 990px;
  overflow: hidden;
  padding: 0;
  position: relative;
  width: 100%;
  background: transparent;
  box-shadow: none;
}
.sponsorBar .sponsorMessage {
  border-bottom: 1px solid #efefef;
  display: block;
  margin: auto;
  padding: 20px;
  position: relative;
  text-align: center;
  text-transform: uppercase;
}
body#home.edit_mode .sponsorBar {
	display: block;
}
body#home.edit_mode .sponsorBar .pageEl {
	float: none;
}
.sponsorBar a.sliderBtn {
  background: none repeat scroll 0 0 #e13b33;
  border-right: medium none;
  color: #fff;
  display: inline;
  float: left;
  font-size: 1.5em;
  padding: 60px 0;
  position: relative;
  text-align: center;
  text-decoration: none;
  width: 5.5%;
  z-index: 9;
}
.sponsorBar a.sliderBtn:hover{
	opacity: 0.7;
}
.sponsorBar a.sliderBtn#nextBtn{

}
.sponsorBar a.sliderBtn#prevBtn{

}
.sponsorInner {
  background-color: #fff;
  display: block;
  float: left;
  max-width: 89%;
  overflow: hidden;
  position: relative;
  width: 100%;
  height: 133px;
}
.sponsorBar .pageEl {
	border-color: #cccccc #ffffff #cccccc #cccccc;
	border-style: solid;
	border-width: 0px;
	display: block;
	float: left;
	width: auto;
	height: auto;
	margin-top: 0px;
	width: 225px;
}
.sponsorBar .pageEl:last-child {

}
.sponsorBar .heroPhotoElement{
	text-align: center;
}
.sponsorBar .heroPhotoElement a{
	width: 100%;
}
.sponsorBar .heroPhotoElement a img{
	width:auto;
	max-height: 125px;
	max-width: 175px;
	margin: auto;
	display: inline;
	vertical-align: middle;
}
.sponsorBar .pageEl .heroPhotoElement a:hover{
	opacity: 0.7;
}
.sponsorBar .pageEl .heroPhotoElement a:active{
	opacity: 0.7;
}
.sponsorBar .column{
	width: auto;
   position: relative;
   /*-webkit-transition: left 1s;
    transition: left 1s;*/
}
.sponsorBar a#nextBtn:active {
  color:#ffffff;
}
.sponsorBar a#prevBtn:active {
  color:#ffffff;
}
