$iconColor: #84929c;
// Hide Pages
/*
[data-nav-level="1"]{
  [title="click to go to 'Assets'"],
  [title="click to go to 'assets'"],
  [title="click to go to 'Site Guide'"],
  [title="click to go to 'site guide'"],
  [title="click to go to 'home'"],
  [title="click to go to 'Home'"]{
    display: none !important;
    flex-grow:0;
  }
}
*/
.asset-page{
  display: none !important;
}
// Additions to Page Manager Panel
.sn-assets, .sn-site-guide, .sn-help {
    display: block;
    font-size: 10px;
    font-weight: 400;
    color: $iconColor;
    text-align: center;
    padding: 10px 0;
    border-bottom: 1px solid #D5DADD;
    line-height: 1.4;
    &:hover{
      color: $iconColor;
      text-decoration: none;
    }
    &:before{
      content:"";
      display:block;
      margin: 0 auto;
      width: 20px;
      height: 25px;
      background-size: contain;
      background-repeat: no-repeat;
      background-position: center center;
    }
}
.sn-assets:before{
  background-image: url(http://assets.ngin.com/site_files/14212/_site/images/assets_icon.svg);
}
.sn-site-guide:before{
  background-image: url(http://assets.ngin.com/site_files/14212/_site/images/site_guide_icon.svg);
}
.sn-help:before{
  content:'\f129';
  font: 1em/1.5em FontAwesome;
  display:block;
  width: 1.3em;
  height: 1.3em;
  border: 1px solid $iconColor;
  border-radius: 100%;
  margin-bottom: 3px;
}
