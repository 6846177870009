/* Single Image Element */

.heroPhotoElement {
  img {
    display: block;
    width: 100%;
    margin: 0 auto;
    // border: $borderWidth solid $borderColor;
  }
  .cutline {
    margin: 0;
    width: 100%;
    // border-bottom: $borderWidth solid $borderColor;
    // border-left: $borderWidth solid $borderColor;
    // border-right: $borderWidth solid $borderColor;
  }
}
