#home{
  #topNav .theme-sub-nav{
    display: none !important;
  }
  #siteContainer,#panelTwo{
    padding: 0px;
  }
  #siteContainer {
    &, #siteContainer.container-fluid {
    margin-top: 0px;
    }
  }
}
.paddingClear{
  .column, .pageElement{
    padding: 0px;
    margin: auto;
  }
}
.bg-black{
  background-color: #000;
}
.linkEl{
  @media screen and (max-width: 768px) {
    h4{
      padding: 15px 15px 0px 15px;
      a{
        border: 0px;
      }
    }
  }
  h4{
    a{
      font: normal 1.7em/1.5em $fontThree;
      text-align: center;
      box-shadow: none;
      @include font-smoother;
      border-right: 1px solid rgba(darken($link-color,5%),1);
      border-left: 1px solid rgba(lighten($link-color,5%),1);
      padding: 15px;
      &:after{
        display: none !important;
      }
    }
  }
}
.cta-tall, .cta-wide{
  padding-left: 15px;
  &:first-child{
    padding-left: 0px;
  }
  .sn-call-to-action.sn-variation-slide-up{
    &:before{
      content: '';
      width: 100%;
      height: 100%;
      top: 0px;
      bottom: 0px;
      background: rgba($link-color,0);
      position: absolute;
      transition: background 300ms ease-in-out;
    }
    .sn-call-to-action-overlay{
      margin: auto;
      width: 50%;
      min-height: 90px !important;
      background: rgba(#000,0.7);
      z-index: 3;
      border-bottom: 0px solid $link-color;
      transition: border 300ms ease-in-out, height 0.5s ease;
      .sn-call-to-action-overlay-text{
        &:after{
          content:'View';
          display: inline-block;
          position: relative;
          border: 1px solid #fff;
          padding: 2px 15px;
          margin-top: 1em;
          text-transform: uppercase;
          font:normal 1.2em/1.5em $fontTwo;
          color: #fff;
        }
        .sn-call-to-action-title{
          line-height: 1.3em;
          margin-bottom: 0px;
          &::first-line{
            font-size: 42px;
          }
        }
      }
    }
    &:hover{
      .sn-call-to-action-overlay{
        border-bottom: 4px solid $link-color;
        height: 85%;
      }
      &:before{
        content: '';
        background: rgba($link-color,0.6);
      }
    }
  }
  @media screen and (max-width: 768px) {
    padding-left: 0px;
    &:first-child{
      padding-left: 0px;
    }
  }
}
.cta-wide{
  .sn-call-to-action.sn-variation-slide-up{
    &:hover{
      .sn-call-to-action-overlay{
      height: 100%;
      }
    }
    .sn-call-to-action-overlay{
      height: 100%;
      border-bottom: 4px solid $link-color;
      .sn-call-to-action-overlay-text{
        .sn-call-to-action-title{
          &:after{
            display: none;
          }
        }
      }
    }
  }
}
.column-4{
  .column{
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .pageEl{
    width: 25%;
  }
  @media screen and (max-width: 768px) {
    // flex-direction: column;
    // .pageEl{
    //   width: 100%;
    // }
    .pageEl{
      width: 100%;
    }
  }
}
.pageElement:not(.documentElement){
  > h3{
    @include special-header;
  }
  .text{
    h1{
      font:normal 48px/1.5em $fontThree;
    }
    h2{
      font:normal 26px/1.5em $fontOne;
    }
    h3{
      font:normal 25px/1.5em $fontThree;
    }
    h4{
      font:normal 18px/1.5em $fontOne;
    }
    h5{
      font:normal 16px/1.5em $fontOne;
    }
    h6{
      font:normal 14px/1.5em $fontOne;
    }
    p{
      font:normal 14px/1.5em $fontTwo;
    }
  }
}
#global-header{
  display: block;
  width: 100%;
  background-color: $accent-color;
  padding: 10px;
  .global-header-inner{
    @include displayCenter($maxWidth);
    display: flex;
    align-items: center;
    .global-header-text{
      width: 70%;
    }
    .pageElement{
      float: none;
      width: 30%;
      text-align: right;
      padding: 0px;
      margin: 0px;
      &.sn-social-media-list.dark{
        .sn-social-media-icon{
          background-color: #313131;
          color: #7e7e7e;
          transition: color 300ms ease-in-out, background-color 300ms ease-in-out;
          &:hover{
            background-color: $link-color;
            color: #fff;
          }
        }
      }
    }
  }
  .global-header-text{
    display: inline-block;
    font: normal 14px/1.5em $fontOne;
    color: #fff;
    text-transform: uppercase;
    strong{
      //
    }
  }
  @media screen and (max-width: 1024px) {
    display: none;
  }
}
#PageSearchBoxTopNav{
  .theme-search-submit{
    &:before{
      content:'|';
      font: normal 1em/1.5em FontAwesome;
      padding: 0px 1em 0px 2em;
    }
    &:after{
      content:'\f002';
      font: normal 1em/1.5em FontAwesome;
      position: absolute;
      left: 1em;
    }
  }
  .theme-search-input{
    display: none;
  }
}
#topNav .site-tagline-text{
  background-image: url('../images/theme/epoch_re_logo.png');
  background-repeat: no-repeat;
  background-position: 95% 0px;
  background-size:contain;
  border-top: 10px solid transparent;
}
#siteFooter{
  padding-top: 60px;
  .global-footer .column{
    display: flex;
    padding: 0px;
    margin: auto;
    .pageEl{
      width: 25%;
      .pageElement{
        text-align: center;
        &.textBlockElement{
          text-align: left;
          tr{
            &:nth-child(even){
              background-color: transparent;
            }
          }
        }
        &.heroPhotoElement img{
          max-width: 120px;
          text-align: left;
          margin: 0px;
        }
        &.sn-social-media-list{
          a{
            background: #fff;
            color: #000 !important;
            transition: color 300ms ease-in-out, background-color 300ms ease-in-out;
            &:hover{
              background-color: $link-color;
              color: #fff !important;
            }
          }
        }
        a{
          color: #fff;
        }
      }
    }
    @media screen and (max-width: 1024px) {
      align-items: center;
      justify-content: center;
      flex-direction: column;
      .pageEl{
        width: 100%;
        margin: auto;
        padding-bottom: 15px;
        .pageElement{
          margin: auto;
          &.heroPhotoElement img{
            margin: auto;
          }
          &.textBlockElement{
            display: none;
          }
        }
      }
    }
  }
  > ul{
    margin-top: 60px;
    margin-bottom: 30px;
    @media screen and (max-width: 1024px) {
      text-align: center;
      font-size: 11px;
      li#poweredByNGIN a{
        margin: auto;
      }
    }
  }
}
@media screen and (max-width: 1024px) {
  html.has-mobile-nav .theme-search-wrapper, html.has-mobile-nav #sn-site-logo{
    display: block;
  }
  html.collapsed-mobile-nav.slide-nav-open{
    .theme-search-wrapper, #sn-site-logo{
      z-index: -1;
    }
  }
  #topNav.nav-fixed .theme-search-bar{
    height: 50px !important;
    left: 0px !important;
  }
  .nav-fixed #sn-site-logo{
    height: 105px;
    width: 100%;
  }
  #topNav .theme-search-wrapper{
    position: absolute;
    top: 0;
  }
  #PageSearchBoxTopNav{
    display: none !important;
  }
  #topNav .site-tagline-text{
    color: transparent;
    text-indent: -99999px;
  }
  #sn-site-logo{
    left: 0px;
    margin-left: 0px;
    width: 100%;
    height: 105px;
    z-index: initial;
    max-width: 205px;
    margin: auto;
    display: block;
    position: relative;
    top: 40px;
  }
  #topNav .theme-search-bar{
    height: 50px;
  }
  #topNav.has-site-logo .theme-search-bar, #topNav.has-site-logo .theme-nav-style-dropdown{
    left: 0px;
    max-width: 100%;
  }
}
.pageEl.sn-vertical-table{
  .report-container{
    overflow: visible;
    table.reportTable{
      display: block;
      thead{
        width: 100%;
        max-width: 30%;
        display: inline-block;
        float: left;
        tr, th{
          width: 100%;
          display: block;
          text-align: left;
          background: #DEDEDE;
          color: #666;
          border-width: 0px;
        }
        th{
          height: 100%;
          line-height: 1.5em;
          border-bottom: 2px solid #fff;
          white-space: normal;
          padding: 10px 15px 10px 10px;
          &.ajax_sort{
            &:after{
              content:'';
              display: none;
            }
          }
        }
        @media screen and (max-width: 1024px) {
          tr,th{
            font: 600 1.2em/1.5em $fontThree;
          }
        }
      }
      tbody{
        width: 100%;
        max-width: 70%;
        display: inline-block;
        float: left;
        tr,td{
          width: 100%;
          display: block;
          text-align: left;
          font: 600 1.5em/1.2em $fontThree;
          @include font-smoother;
          background: #DEDEDE;
          border-width: 0px;
        }
        td{
          height: 100%;
          line-height: 1.5em;
          border-bottom: 2px solid #fff;
          padding: 10px 5px 10px 5px;
        }
        @media screen and (max-width: 1024px) {
          tr,td{
            font: 600 1.2em/1.5em $fontThree;
          }
        }
      }
    }
  }
}
